@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5ff;
  color: #2e3238;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dt-size-is-1-2 {
  aspect-ratio: 1 / 2;
}

.dt-size-is-1-1 {
  aspect-ratio: 1 / 1;
}

.dt-size-is-2-1 {
  aspect-ratio: 2 / 1;
}

.dt-size-is-3-1 {
  aspect-ratio: 3 / 1;
}
